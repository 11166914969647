export const CHECK_RESTAURANT_OPERATION_SERVICE =
  "CHECK_RESTAURANT_OPERATION_SERVICE";
export const GET_RESTAURANTS_BASED_ON_CATEGORY =
  "GET_RESTAURANTS_BASED_ON_CATEGORY";
export const GET_RESTAURANTS_CATEGORIES = "GET_RESTAURANTS_CATEGORIES";

export const GET_DELIVERY_RESTAURANTS = "GET_DELIVERY_RESTAURANTS";
export const GET_SELFPICKUP_RESTAURANTS = "GET_SELFPICKUP_RESTAURANTS";
export const GET_FAVORITE_RESTAURANTS = "GET_FAVORITE_RESTAURANTS";
export const GET_FAVORITE_ITEMS = "GET_FAVORITE_ITEMS";

export const GET_RESTAURANTS_ITEM_CATEGORIES =
  "GET_RESTAURANTS_ITEM_CATEGORIES";
export const GET_RESTAURANTS_DEAL_ITEMS = "GET_RESTAURANTS_DEAL_ITEMS";
export const GET_RESTAURANTS_COMBOS = "GET_RESTAURANTS_COMBOS";
export const GET_RESTAURANTS_FEATURED_ITEMS = "GET_RESTAURANTS_FEATURED_ITEMS";
export const GET_LINKED_ITEMS = "GET_LINKED_ITEMS";
