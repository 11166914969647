import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import useScript from 'useScript';
import { Carousel } from "react-responsive-carousel";

class V4 extends Component {
  state = {
    activeClass: "",
    downloadCount: 0,
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "normal";
      //    console.log('window.scrollY', window.scrollY)
      if (window.scrollY >= 100) {
        activeClass = "on-scroll";
      }
      if (window.scrollY >= 2030) {
        // this.incrementCount();
      }
      this.setState({ activeClass });
    });
  }

  incrementCount = () => {
    for (let i = this.state.downloadCount; i <= 5000; i++) {
      setTimeout(function() {
        this.setState({
          downloadCount: i + 1,
        });
      }, 1000);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="stylesheet"
            href="/static-assets/use.fontawesome.com/releases/v5.8.2/css/all.css"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap"
          />
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css"
            rel="stylesheet"
          />
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/jmpress/0.4.5/basic-animations.min.css"
          />
          <link rel="stylesheet" href="/static-assets/webfont/webfont.css" />
          <link rel="stylesheet" href="/static-assets/css/master.css" />
          <link rel="stylesheet" href="/static-assets/css/realapp.css" />
          <link
            rel="stylesheet"
            href="/static-assets/use.fontawesome.com/releases/v5.8.2/css/all.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
        </Helmet>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="hero-showcase">
            <div className="col-md-12" id="magic-btn-gy">
              <div className="phone phone-use">
                <div className="iframe-wrapper">
                  <iframe
                    title="appIframe"
                    src={window.location}
                    frameBorder="0"
                    id="appIframe"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <section className="regular dark stats" id="counter">
            <div className="container">
                <div className="row">

                    <div className="col-md-3">
                        <h6>DOWNLOADS</h6>
                        <h1><span className="counter-value" data-count="5000">5000</span>+</h1>
                    </div>

                    <div className="col-md-3">
                        <h6>STORES</h6>
                        <h1><span className="counter-value" data-count="45">45</span>+</h1>
                    </div>

                    <div className="col-md-3">
                        <h6>ITEMS</h6>
                        <h1><span className="counter-value" data-count="3200">3200</span>+</h1>
                    </div>

                    <div className="col-md-3">
                        <h6>ORDERS</h6>
                        <h1><span className="counter-value" data-count="10000">10000</span>+</h1>
                    </div>

                </div>
            </div>
        </section> */}
        </div>
        <Helmet>
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"
          />
          <script
            type="text/javascript"
            src="/static-assets/demo/stretchy-navigation/js/modernizr.js"
          />
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"
          />
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js"
          />
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/js/mdb.min.js"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js" />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default V4;
