import React, { Component } from "react";
import Meta from "../../../helpers/meta";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { getSingleLanguageData } from "../../../../services/languages/actions";
import { updateUserInfo } from "../../../../services/user/actions";
import { getPages } from "../../../../services/pages/actions";
import BackWithSearch from "../../Elements/BackWithSearch";
class Pages extends Component {
  state = {
    avatarPopup: false,
  };
  w;
  componentDidMount() {
    const { user } = this.props;
    if (localStorage.getItem("storeColor") !== null) {
      if (user.success) {
        this.props.getPages();
        this.updateUserInfo(user);
      }
    }
  }
  updateUserInfo = () => {
    const { user } = this.props;
    this.props
      .updateUserInfo(user.data.id, user.data.auth_token)
      .then((response) => {
        if (response) {
          if (response.payload.data.id) {
            this.setState({ avatarPopup: false });
          }
        }
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (localStorage.getItem("userPreferedLanguage")) {
        this.props.getSingleLanguageData(
          localStorage.getItem("userPreferedLanguage")
        );
      } else {
        if (nextProps.languages.length) {
          console.log("Fetching Translation Data...");
          const id = nextProps.languages.filter(
            (lang) => lang.is_default === 1
          )[0].id;
          this.props.getSingleLanguageData(id);
        }
      }
    }
  }

  handleOnChange = (event) => {
    // console.log(event.target.value);
    this.props.getSingleLanguageData(event.target.value);
    localStorage.setItem("userPreferedLanguage", event.target.value);
  };

  render() {
    if (window.innerWidth > 768) {
      return <Redirect to="/" />;
    }
    if (localStorage.getItem("storeColor") === null) {
      return <Redirect to={"/"} />;
    }
    const { user, pages } = this.props;

    if (!user.success) {
      return (
        //redirect to login page if not loggedin
        <Redirect to={"/login"} />
      );
    }
    // const languages = JSON.parse(localStorage.getItem("state")).languages;
    // console.log(languages);
    const page = pages.find(
      (item) => item.slug === this.props.match.params.type
    );
    if (!page.body) {
      return <Redirect to={"/my-account"} />;
    }
    return (
      <React.Fragment>
        <Meta
          seotitle={localStorage.getItem("footerAccount")}
          seodescription={localStorage.getItem("seoMetaDescription")}
          ogtype="website"
          ogtitle={localStorage.getItem("seoOgTitle")}
          ogdescription={localStorage.getItem("seoOgDescription")}
          ogurl={window.location.href}
          twittertitle={localStorage.getItem("seoTwitterTitle")}
          twitterdescription={localStorage.getItem("seoTwitterDescription")}
        />
        <BackWithSearch
          boxshadow={true}
          has_title={false}
          title={""}
          disable_search={true}
          dark={false}
          home={true}
          homeButton={true}
        />
        <div className="px-15  mt-30">
          <br />
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: page.body ? page.body : "",
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  pages: state.pages.pages,
  languages: state.languages.languages,
  language: state.languages.language,
});

export default connect(
  mapStateToProps,
  { getPages, getSingleLanguageData, updateUserInfo }
)(Pages);
