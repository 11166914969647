import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./components/App";
import Loadable from "react-loadable";
import Loading from "./components/helpers/loading";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import withTracker from "./withTracker";
import CategoryList from "./components/Mobile/HomeV1/CategoryList";
import DealOfTheDayList from "./components/Mobile/HomeV1/DealOfTheDayList";
import FrequentlyBoughtList from "./components/Mobile/HomeV1/FrequentlyBoughtList";
// import DynamicFeaturedSectionStyle1 from "./components/Mobile/HomeV1/DynamicFeaturedSectionStyle1";
import DynamicFeaturedSections from "./components/Mobile/DynamicFeaturedSections";
import axios from "axios";
import ItemLinkedList from "./components/Mobile/HomeV1/ItemLinkedList";
import Pages from "./components/Mobile/Account/Pages";

console.log("axios.defaults.payload.data", axios.defaults);
// axios.defaults.payload.data['domain_api'] = window.location.origin;

axios.interceptors.request.use(
  (request) => {
    console.log(request);
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log(response);
    // Edit response config
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// import NotFound from "./components/NotFound";
const NotFound = Loadable({
  loader: () => import("./components/NotFound"),
  loading: () => <Loading />,
});

// import Location from "./components/Mobile/Location";
const Location = Loadable({
  loader: () => import("./components/Mobile/Location"),
  loading: () => <Loading />,
});

// import Items from "./components/Mobile/Items";
// const Items = Loadable({
// 	loader: () => import("./components/Mobile/Items"),
// 	loading: () => <Loading />,
// });

// import Login from "./components/Mobile/Auth/Login";
const Login = Loadable({
  loader: () => import("./components/Mobile/Auth"),
  loading: () => <Loading />,
});

// import Register from "./components/Mobile/Auth/Register";
const Register = Loadable({
  loader: () => import("./components/Mobile/Auth/Register"),
  loading: () => <Loading />,
});

// import CartPage from "./components/Mobile/Cart";
const CartPage = Loadable({
  loader: () => import("./components/Mobile/Cart"),
  loading: () => <Loading />,
});
const CartStepTwo = Loadable({
  loader: () => import("./components/Mobile/Cart/CartStepTwo"),
  loading: () => <Loading />,
});

// import MemberCartPage from "./components/Mobile/MemberCart";
const MemberCartPage = Loadable({
  loader: () => import("./components/Mobile/MemberCart"),
  loading: () => <Loading />,
});

// import Account from "./components/Mobile/Account";
const Account = Loadable({
  loader: () => import("./components/Mobile/Account"),
  loading: () => <Loading />,
});

// import Explore from "./components/Mobile/Explore";
const Explore = Loadable({
  loader: () => import("./components/Mobile/Explore"),
  loading: () => <Loading />,
});

// import Explore from "./components/Mobile/Explore";
const Membership = Loadable({
  loader: () => import("./components/Mobile/Membership"),
  loading: () => <Loading />,
});

const MembersPlans = Loadable({
  loader: () => import("./components/Mobile/MembersPlans"),
  loading: () => <Loading />,
});

// import Explore from "./components/Mobile/Explore";
const MembersMart = Loadable({
  loader: () => import("./components/Mobile/MembersMart"),
  loading: () => <Loading />,
});

// import Addresses from "./components/Mobile/Account/Addresses";
const Addresses = Loadable({
  loader: () => import("./components/Mobile/Account/Addresses"),
  loading: () => <Loading />,
});

// import Checkout from "./components/Mobile/Checkout";
const Checkout = Loadable({
  loader: () => import("./components/Mobile/Checkout"),
  loading: () => <Loading />,
});

// import RunningOrder from "./components/Mobile/RunningOrder";
const RunningOrder = Loadable({
  loader: () => import("./components/Mobile/RunningOrder"),
  loading: () => <Loading />,
});

// import Orders from "./components/Mobile/Account/Orders";
const Orders = Loadable({
  loader: () => import("./components/Mobile/Account/Orders"),
  loading: () => <Loading />,
});

const Transactions = Loadable({
  loader: () => import("./components/Mobile/Account/Transactions"),
  loading: () => <Loading />,
});

// const MemberPlus = Loadable({
//   loader: () => import("./components/Mobile/MemberPlus"),
//   loading: () => <Loading />,
// });

// import WalletPage from "./components/Mobile/Account/Wallet";
const WalletPage = Loadable({
  loader: () => import("./components/Mobile/Account/Wallet"),
  loading: () => <Loading />,
});
/* Delivery */
// import Delivery from "./components/Delivery";
const Delivery = Loadable({
  loader: () => import("./components/Delivery"),
  loading: () => <Loading />,
});

// import DeliveryLogin from "./components/Delivery/Login";
const DeliveryLogin = Loadable({
  loader: () => import("./components/Delivery/Login"),
  loading: () => <Loading />,
});

// import DeliveryOrders from "./components/Delivery/Orders";
const DeliveryOrders = Loadable({
  loader: () => import("./components/Delivery/Orders"),
  loading: () => <Loading />,
});

// import ViewOrder from "./components/Delivery/ViewOrder";
const ViewOrder = Loadable({
  loader: () => import("./components/Delivery/ViewOrder"),
  loading: () => <Loading />,
});

// import GeoLocationPage from "./components/Mobile/GeoLocationPage";
const GeoLocationPage = Loadable({
  loader: () => import("./components/Mobile/GeoLocationPage"),
  loading: () => <Loading />,
});

// import SingleItem from "./components/Mobile/Items/SingleItem";
// const SingleItem = Loadable({
// 	loader: () => import("./components/Mobile/Items/SingleItem"),
// 	loading: () => <Loading />,
// });

const CategoryItemsParent = Loadable({
  loader: () =>
    import("./components/Mobile/CategoryItemsV1/CategoryItemsParent"),
  loading: () => <Loading />,
});

const ItemsParent = Loadable({
  loader: () => import("./components/Mobile/Items/ItemsParent"),
  loading: () => <Loading />,
});

const SingleItemParent = Loadable({
  loader: () => import("./components/Mobile/Items/SingleItemParent"),
  loading: () => <Loading />,
});

const SinglePage = Loadable({
  loader: () => import("./components/SinglePage"),
  loading: () => <Loading />,
});

const FeaturedSections = Loadable({
  loader: () => import("./components/Mobile/FeaturedSections"),
  loading: () => <Loading />,
});

const ForgotPassword = Loadable({
  loader: () => import("./components/Mobile/Auth/ForgotPassword"),
  loading: () => <Loading />,
});

const RestaurantListOnCategory = Loadable({
  loader: () => import("./components/Mobile/Home/RestaurantListOnCategory"),
  loading: () => <Loading />,
});

const RatingAndReview = Loadable({
  loader: () => import("./components/Mobile/Account/Orders/RatingAndReview"),
  loading: () => <Loading />,
});
const ViewStoreReviews = Loadable({
  loader: () => import("./components/Mobile/StoreReviews"),
  loading: () => <Loading />,
});

const Alerts = Loadable({
  loader: () => import("./components/Mobile/Alerts"),
  loading: () => <Loading />,
});

// const FavoriteRestaurantList = Loadable({
//   loader: () => import("./components/Mobile/Home/FavoriteRestaurants"),
//   loading: () => <Loading />,
// });

// const FavoriteItemList = Loadable({
//   loader: () => import("./components/Mobile/Home/FavoriteItems"),
//   loading: () => <Loading />,
// });

const LoginAsCustomer = Loadable({
  loader: () => import("./components/Mobile/Modules/LoginAsCustomer"),
  loading: () => <Loading />,
});

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

ReactDOM.render(
  <Root>
    <BrowserRouter>
      <React.Fragment>
        <Route component={ScrollToTop} />
        <Switch>
          {/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
          <Route path={"/"} exact component={withTracker(App)} />

          {/* Test Routes*/}
          {/* <Route path={"/saurabh/test"} exact component={TestComponent} /> */}

          <Route
            path={"/search-location"}
            exact
            component={withTracker(Location)}
          />
          <Route
            path={"/my-location"}
            exact
            component={withTracker(GeoLocationPage)}
          />

          <Route
            path={"/categories/stores"}
            exact
            component={withTracker(RestaurantListOnCategory)}
          />

          {/* <Route path={"/stores/:restaurant"} exact component={withTracker(Items)} /> */}
          <Route
            path={"/stores/:restaurant"}
            exact
            component={withTracker(ItemsParent)}
          />
          <Route
            path={"/categories/:restaurant/:id"}
            exact
            component={withTracker(CategoryItemsParent)}
          />
          <Route
            path={"/categories/:restaurant"}
            exact
            component={withTracker(CategoryList)}
          />
          <Route
            path={"/my-account/pages/:type"}
            exact
            component={withTracker(Pages)}
          />
          <Route
            path={"/deal-of-the-day-items/:restaurant"}
            exact
            component={withTracker(DealOfTheDayList)}
          />
          <Route
            path={"/items/:slug"}
            exact
            component={withTracker(ItemLinkedList)}
          />

          <Route
            path={"/dynamic-featured-sections/:slug"}
            exact
            component={withTracker(DynamicFeaturedSections)}
          />
          <Route
            path={"/frequently-bought-items/:restaurant"}
            exact
            component={withTracker(FrequentlyBoughtList)}
          />
          {/* <Route path={"/stores/:restaurant/:id"} exact component={withTracker(SingleItem)} /> */}
          <Route
            path={"/stores/:restaurant/:id"}
            exact
            component={withTracker(SingleItemParent)}
          />

          <Route path={"/explore"} exact component={withTracker(Explore)} />
          <Route
            path={"/members-mart"}
            exact
            component={withTracker(MembersMart)}
          />
          <Route
            path={"/membership"}
            exact
            component={withTracker(Membership)}
          />
          <Route
            path={"/membership-plans"}
            exact
            component={withTracker(MembersPlans)}
          />
          <Route
            path={"/featured-sections/:slug"}
            exact
            component={withTracker(FeaturedSections)}
          />

          <Route path={"/login"} exact component={withTracker(Login)} />
          <Route
            path={"/login/forgot-password"}
            exact
            component={withTracker(ForgotPassword)}
          />
          <Route path={"/register"} exact component={withTracker(Register)} />

          <Route path={"/my-account"} exact component={withTracker(Account)} />
          <Route path={"/alerts"} exact component={withTracker(Alerts)} />
          <Route
            path={"/my-addresses"}
            exact
            component={withTracker(Addresses)}
          />
          <Route
            path={"/my-wallet"}
            exact
            component={withTracker(WalletPage)}
          />
          <Route path={"/my-orders"} exact component={withTracker(Orders)} />
          <Route
            path={"/transactions"}
            exact
            component={withTracker(Transactions)}
          />
          {/* <Route
            path={"/plus-membership"}
            exact
            component={withTracker(MemberPlus)}
          /> */}
          <Route
            path={"/rate-order/:id"}
            exact
            component={withTracker(RatingAndReview)}
          />
          <Route
            path={"/reviews/:slug"}
            exact
            component={withTracker(ViewStoreReviews)}
          />

          <Route path={"/checkout"} exact component={withTracker(Checkout)} />
          <Route
            path={"/running-order/:unique_order_id"}
            exact
            component={withTracker(RunningOrder)}
          />

          <Route path={"/cart"} exact component={withTracker(CartPage)} />
          <Route
            path={"/cart/shipping"}
            exact
            component={withTracker(CartStepTwo)}
          />

          <Route
            path={"/member-cart"}
            exact
            component={withTracker(MemberCartPage)}
          />

          <Route
            path={"/pages/:slug"}
            exact
            component={withTracker(SinglePage)}
          />
          {/* <Route
            path={"/my-favorite-stores"}
            exact
            component={withTracker(FavoriteRestaurantList)}
          /> */}
          {/* <Route
            path={"/my-favorite-items"}
            exact
            component={withTracker(FavoriteItemList)}
          /> */}
          {/* Delivery Routes */}
          <Route path={"/delivery"} exact component={Delivery} />
          <Route path={"/delivery/login"} exact component={DeliveryLogin} />
          <Route path={"/delivery/orders"} exact component={DeliveryOrders} />
          <Route
            path={"/delivery/orders/:unique_order_id"}
            exact
            component={ViewOrder}
          />
          <Route
            path={"/delivery/completed-orders"}
            exact
            component={Delivery}
          />
          {/* Common Routes */}
          {/* Admin Login as Customer Module Routes */}
          <Route
            path={"/auth/login-as-customer/:id?"}
            exact
            component={LoginAsCustomer}
          />
          {/* END Admin Login as Customer Module Routes */}
          <Route component={NotFound} />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  </Root>,
  document.getElementById("root")
);
