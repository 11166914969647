import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";

import { withRouter } from "react-router-dom";
import BackWithSearch from "../../Elements/BackWithSearch";

import { connect } from "react-redux";
import { getRestaurantsCombos } from "../../../../services/restaurant/actions";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import { setFavoriteItem } from "../../../../services/items/actions";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

class Combos extends Component {
  state = {
    update: true,
    total: null,
    restaurants_combos: [],
    loading: false,
    loading_more: true,
    no_restaurants_combos: false,
  };

  componentDidMount() {
    this.__getRestaurantsCombos();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state.data) {
      return true;
    } else {
      return false;
    }
  }

  __getRestaurantsCombos = () => {
    if (localStorage.getItem("userSetAddress")) {
      this.setState({
        loading: true,
      });
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getRestaurantsCombos(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants_combos) => {
          if (restaurants_combos && restaurants_combos.payload.length) {
            this.setState({
              total: restaurants_combos.payload.length,
              no_restaurants_combos: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              no_restaurants_combos: true,
            });
          }
        });
    }
  };

  forceStateUpdate = () => {
    this.forceUpdate();
    const restaurant = this.props.match.params.restaurant;

    if (restaurant !== "all") {
      this.props.forceStateUpdate();
    }

    setTimeout(() => {
      if (this.state.update) {
        this.setState({ update: false });
      } else {
        this.setState({ update: true });
      }
    }, 100);
  };

  __getDiscountPercentage = (item) => {
    var discount = 0;
    if (item.has_variant) {
      let sale_price = this.__getSalePrice(item.variants);
      let regular_price = this.__getRegularPrice(item.variants);
      if (parseFloat(sale_price) >= parseFloat(regular_price)) {
        return false;
      }
      discount =
        ((parseFloat(regular_price) - parseFloat(sale_price)) /
          parseFloat(regular_price)) *
        100;
    } else {
      let sale_price = item.price;
      let regular_price = item.old_price;
      if (parseFloat(sale_price) >= parseFloat(regular_price)) {
        return false;
      }
      discount =
        ((parseFloat(regular_price) - parseFloat(sale_price)) /
          parseFloat(regular_price)) *
        100;
    }
    return discount !== 0 ? discount.toFixed(0) : false;
  };

  __formatPrice = (price) => {
    return parseFloat(price).toFixed(0);
  };

  render() {
    const {
      addProduct,
      removeProduct,
      cartProducts,
      forceRestaurantItemsUpdate,
    } = this.props;

    const isItemExistInCart = (item_id) => {
      let cc = cartProducts.find((cartItem) => {
        return cartItem.id === item_id;
      });
      if (cc !== undefined) {
        return true;
      }
      return false;
    };

    const { history } = this.props;
    const restaurant = this.props.match.params.restaurant;

    if (forceRestaurantItemsUpdate === true) {
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getRestaurantsCombos(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants_combos) => {
          if (restaurants_combos && restaurants_combos.payload.length) {
            this.setState({
              total: restaurants_combos.payload.length,
              no_restaurants_combos: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              no_restaurants_combos: true,
            });
          }
        });
      this.props.updateRestaurantItemsState(false);
    }

    return (
      <React.Fragment>
        {restaurant !== undefined ? (
          <div className="bg-white">
            <React.Fragment>
              <BackWithSearch
                ref={(node) => {
                  this.child = node;
                }}
                history={history}
                boxshadow={false}
                has_restaurant_info={true}
                restaurant={restaurant}
                disable_search={true}
                homeButton={true}
                shareButton={true}
              />
            </React.Fragment>
          </div>
        ) : (
          <React.Fragment />
        )}

        {this.state.no_restaurants_combos && (
          <div className="bg-light">
            <h1 className="restaurant-count mb-0 mr-2 px-15">
              {localStorage.getItem("noItemsMessage")}
            </h1>
          </div>
        )}

        {this.state.total && (
          <div
            className={
              "col-sm-12 bg-white mb-30" +
              (restaurant !== undefined
                ? " pt-50 view-all-deal-of-the-day-section"
                : " pt-10")
            }
          >
            {this.state.loading ? (
              <ContentLoader
                height={378}
                width={400}
                speed={1.2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
              </ContentLoader>
            ) : (
              <React.Fragment>
                <div className="combos-title-section my-10">
                  <span className="title">
                    <strong>
                      {localStorage.getItem("combosSectionTitle")}
                    </strong>
                  </span>
                  <span className="subtitle">
                    {localStorage.getItem("combosSectionSubTitle")}
                  </span>

                  {/* { restaurant !== undefined ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <DelayLink
                                            to={"combos/all"}
                                            delay={200}
                                            className="block text-center mb-3 view-all"
                                        >
                                            View All
                                            <i
                                                className="si si-arrow-right nav-location-icon ml-1"
                                            />
                                        </DelayLink>
                                    )} */}
                </div>
                <div className="combos-content-section">
                  {this.props.restaurants_combos.length === 0 ? (
                    <ContentLoader
                      height={378}
                      width={400}
                      speed={1.2}
                      primaryColor="#f3f3f3"
                      secondaryColor="#ecebeb"
                    >
                      <rect
                        x="20"
                        y="20"
                        rx="4"
                        ry="4"
                        width="80"
                        height="78"
                      />
                      <rect
                        x="144"
                        y="30"
                        rx="0"
                        ry="0"
                        width="115"
                        height="18"
                      />
                      <rect
                        x="144"
                        y="60"
                        rx="0"
                        ry="0"
                        width="165"
                        height="16"
                      />

                      <rect
                        x="20"
                        y="145"
                        rx="4"
                        ry="4"
                        width="80"
                        height="78"
                      />
                      <rect
                        x="144"
                        y="155"
                        rx="0"
                        ry="0"
                        width="115"
                        height="18"
                      />
                      <rect
                        x="144"
                        y="185"
                        rx="0"
                        ry="0"
                        width="165"
                        height="16"
                      />

                      <rect
                        x="20"
                        y="270"
                        rx="4"
                        ry="4"
                        width="80"
                        height="78"
                      />
                      <rect
                        x="144"
                        y="280"
                        rx="0"
                        ry="0"
                        width="115"
                        height="18"
                      />
                      <rect
                        x="144"
                        y="310"
                        rx="0"
                        ry="0"
                        width="165"
                        height="16"
                      />
                    </ContentLoader>
                  ) : (
                    <React.Fragment>
                      <Tabs>
                        <TabList>
                          {this.props.restaurants_combos.map((item, index) => (
                            <React.Fragment key={item.id}>
                              <Tab key={item.id}>{item.name}</Tab>
                            </React.Fragment>
                          ))}
                        </TabList>

                        {this.props.restaurants_combos.map((item, index) => (
                          <TabPanel key={item.id}>
                            <React.Fragment>
                              <div className="single-combos-section mb-25">
                                <div
                                  className="combos-content"
                                  style={{
                                    background: "url(" + item.background + ")",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100% 200px",
                                  }}
                                >
                                  {this.__getDiscountPercentage(item) && (
                                    <div className="item-discount-tag">
                                      <img
                                        alt="item-discount"
                                        src="../../assets/img/various/discount-ribbon.svg"
                                      />
                                      <span className="discount-price">
                                        {this.__getDiscountPercentage(item)}%
                                        OFF
                                      </span>
                                    </div>
                                  )}
                                  <DelayLink
                                    to={
                                      "../stores/" +
                                      item.restaurant_slug +
                                      "/" +
                                      item.id
                                    }
                                    delay={200}
                                  >
                                    {/* <div className="item-image-section">
                                                                            <img
                                                                                src={item.image}
                                                                                alt={item.name}
                                                                                className="slider-wrapper__img slider-cust-img"
                                                                            />
                                                                        </div> */}
                                    <div className="item-details">
                                      {/* <div className="font-w600 text-left">
                                                                                <h2 className="item-title mb-10">
                                                                                    <strong>
                                                                                        {item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}
                                                                                    </strong>
                                                                                </h2>
                                                                            </div>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item.desc,
                                                                                }}
                                                                                className="desc-text"
                                                                            /> */}
                                      <div className="item-color-price font-w600 text-left mt-5">
                                        <React.Fragment>
                                          <span className="sale-price mr-2">
                                            {localStorage.getItem(
                                              "currencySymbolAlign"
                                            ) === "left" &&
                                              localStorage.getItem(
                                                "currencyFormat"
                                              )}{" "}
                                            {parseFloat(item.price).toFixed(0)}
                                          </span>
                                          {item.old_price != "0.00" && (
                                            <span className="regular-price">
                                              {localStorage.getItem(
                                                "currencySymbolAlign"
                                              ) === "left" &&
                                                localStorage.getItem(
                                                  "currencyFormat"
                                                )}
                                              <s>
                                                {parseFloat(
                                                  item.old_price
                                                ).toFixed(0)}
                                              </s>
                                            </span>
                                          )}
                                        </React.Fragment>
                                      </div>
                                    </div>
                                    <Ink duration="500" hasTouch={false} />
                                  </DelayLink>

                                  <div className="item-actions">
                                    {item.is_active ? (
                                      <React.Fragment>
                                        {isItemExistInCart(item.id) ? (
                                          <React.Fragment>
                                            <div
                                              className="btn-group btn-group-sm"
                                              role="group"
                                              aria-label="btnGroupIcons1"
                                            >
                                              <React.Fragment>
                                                <button
                                                  type="button"
                                                  className="btn btn-add-remove"
                                                  style={{
                                                    color: localStorage.getItem(
                                                      "cartColor-bg"
                                                    ),
                                                  }}
                                                  onClick={() => {
                                                    item.quantity = 1;
                                                    removeProduct(item);
                                                    this.forceStateUpdate();
                                                  }}
                                                >
                                                  <span className="btn-dec">
                                                    -
                                                  </span>
                                                  <Ink duration="500" />
                                                </button>

                                                <input
                                                  type="text"
                                                  readOnly
                                                  value={
                                                    cartProducts.find(
                                                      (cp) => cp.id === item.id
                                                    ).quantity
                                                  }
                                                />

                                                <button
                                                  type="button"
                                                  className="btn btn-add-remove"
                                                  style={{
                                                    color: localStorage.getItem(
                                                      "cartColor-bg"
                                                    ),
                                                  }}
                                                  onClick={() => {
                                                    addProduct(item);
                                                    this.forceStateUpdate();
                                                  }}
                                                >
                                                  <span className="btn-inc">
                                                    +
                                                  </span>
                                                  <Ink duration="500" />
                                                </button>
                                              </React.Fragment>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className="btn-group btn-group-sm"
                                              role="group"
                                              aria-label="btnGroupIcons1"
                                            >
                                              <button
                                                type="button"
                                                className="btn btn-add-remove btn-custom-add"
                                                style={{
                                                  color: localStorage.getItem(
                                                    "cartColor-bg"
                                                  ),
                                                }}
                                                onClick={() => {
                                                  item.quantity = 1;
                                                  addProduct(item);
                                                  this.forceStateUpdate();
                                                }}
                                              >
                                                Add
                                                <Ink duration="500" />
                                              </button>
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      <div className="text-danger text-item-not-available">
                                        {localStorage.getItem(
                                          "cartItemNotAvailable"
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          </TabPanel>
                        ))}
                      </Tabs>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}

            {this.state.loading_more ? (
              <div className="">
                <ContentLoader
                  height={120}
                  width={400}
                  speed={1.2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                  <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                  <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
                </ContentLoader>
              </div>
            ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
  restaurants_combos: state.restaurant.restaurants_combos,
  cartProducts: state.cart.products,
  user: state.user.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getRestaurantsCombos,
      addProduct,
      removeProduct,
      setFavoriteItem,
    }
  )(Combos)
);
