import React, { Component } from "react";

import Ink from "react-ink";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PWAInstallation from "../PWAInstallation";

class Nav extends Component {
  static contextTypes = {
    router: () => null,
  };

  render() {
    const { alertUnreadTotal } = this.props;
    return (
      <React.Fragment>
        <div
          className="col-12 p-0 sticky-top  "
          style={{
            height: "75px",
            alignContent: "center",
            background: "transparent",
          }}
        >
          <div className="block m-0" style={{ background: "transparent" }}>
            <div
              className="block-content p-0"
              style={{ background: "transparent" }}
            >
              <div
                className="input-group search-box"
                style={{ background: "transparent" }}
              >
                {!this.props.disable_back_button && (
                  <div
                    className="input-group-prepend"
                    style={{ background: "transparent" }}
                  >
                    <button
                      type="button"
                      className="btn search-navs-btns"
                      style={{ position: "relative" }}
                    >
                      <i className="si si-arrow-left" />
                      <Ink duration="500" />
                    </button>
                  </div>
                )}
                <p
                  className="form-control search-input"
                  style={{ background: "transparent" }}
                >
                  {this.props.logo &&
                    (this.props.logoLink ? (
                      <Link to="/">
                        <img
                          src={`/assets/img/logos/${localStorage.getItem(
                            "storeLogo"
                          )}`}
                          alt={localStorage.getItem("storeName")}
                          className="store-logo"
                          style={{ visibility: "hidden" }}
                        />
                      </Link>
                    ) : (
                      <img
                        src={`/assets/img/logos/${localStorage.getItem(
                          "storeLogo"
                        )}`}
                        style={{ visibility: "hidden" }}
                        alt={localStorage.getItem("storeName")}
                        className="store-logo"
                      />
                    ))}
                </p>
                <div
                  className="input-group-append align-center "
                  style={{ background: "transparent" }}
                >
                  <PWAInstallation type={"header"} />
                  <button
                    type="submit"
                    className="btn nav-location nav-home-location truncate-text pr-15 "
                    style={{
                      position: "relative",
                      maxWidth: "100%",
                      background: "transparent",
                    }}
                    onClick={() => {
                      // this.props.user.success
                      //   ? this.context.router.history.push("/my-addresses")
                      //   :
                      this.context.router.history.push("/search-location");
                    }}
                  >
                    {localStorage.getItem("userSetAddress") && (
                      <React.Fragment>
                        <div
                          className="flex align-center deliver-to-with-address fw600 f-12"
                          style={{ color: "black" }}
                        >
                          <img
                            src="./assets/img/home/pinned-location.png"
                            alt="location"
                            className="mr-2"
                          />{" "}
                          {localStorage.getItem("deliveryToText")}
                          <span
                            className="fw600"
                            style={{
                              top: "1px",
                            }}
                          >
                            {JSON.parse(localStorage.getItem("userSetAddress"))
                              .tag !== null ? (
                              <strong className="text-uppercase mr-1">
                                {
                                  JSON.parse(
                                    localStorage.getItem("userSetAddress")
                                  ).tag
                                }
                              </strong>
                            ) : null}
                          </span>
                        </div>
                      </React.Fragment>
                    )}
                    <Ink duration="500" />
                  </button>

                  {/* <button
										type="button"
										className="btn search-navs-btns nav-home-btn"
										style={{ position: "relative" }}
										onClick={() => {
											setTimeout(() => {
												this.context.router.history.push("/alerts");
											}, 200);
										}}
									>
										<span
											className="header-cart-quantity-badge"
											style={{ backgroundColor: localStorage.getItem("storeColor") }}
										>
											{alertUnreadTotal}
										</span>
										<i className="si si-bell fa-2x" />
										<Ink duration="500" />
									</button> */}
                  {/* <button
										type="submit"
										className="btn nav-location truncate-text"
										style={{ position: "relative", maxWidth: window.innerWidth - 130 }}
										onClick={() => {
											this.props.loggedin
												? this.context.router.history.push("/my-addresses")
												: this.context.router.history.push("/search-location");
										}}
									>
										{localStorage.getItem("userSetAddress") && (
											<React.Fragment>
												<span>
													{JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
														<strong className="text-uppercase mr-1">
															{JSON.parse(localStorage.getItem("userSetAddress")).tag}
														</strong>
													) : null}

													{JSON.parse(localStorage.getItem("userSetAddress")).house !==
													null ? (
														<span>
															{JSON.parse(localStorage.getItem("userSetAddress")).house
																.length > 12
																? `${JSON.parse(
																		localStorage.getItem("userSetAddress")
																  ).house.substring(0, 12)}...`
																: JSON.parse(localStorage.getItem("userSetAddress"))
																		.house}
														</span>
													) : (
														<span>
															{JSON.parse(localStorage.getItem("userSetAddress")).address
																.length > 18
																? `${JSON.parse(
																		localStorage.getItem("userSetAddress")
																  ).address.substring(0, 18)}...`
																: JSON.parse(localStorage.getItem("userSetAddress"))
																		.address}
														</span>
													)}
												</span>
											</React.Fragment>
										)}
										<i
											className="si si-arrow-right nav-location-icon ml-1"
											style={{ color: localStorage.getItem("storeColor") }}
										/>
										<Ink duration="500" />
									</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  alertUnreadTotal: state.alert.alertUnreadTotal,
  user: state.user.user,
});
export default connect(
  mapStateToProps,
  {}
)(Nav);
