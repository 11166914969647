import React, { Component } from "react";
import Ink from "react-ink";
import Lottie from "react-lottie";
import animationData from "./house.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
class NoAreaAlert extends Component {
  static contextTypes = {
    router: () => null,
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={" text-center position-absolute"}
          style={{
            top: 0,
            background: "white",
            zIndex: "101",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // backgroundImage: ' URL("/assets/img/unavailable.svg")',
            // backgroundSize: "cover",
          }}
        >
          <div
            className="position-relative"
            style={{
              height: "65vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            <Lottie options={defaultOptions} height={250} width={"100%"} />
          </div>
          <div
            className="flex-column"
            style={{
              height: "36vh",
              backgroundColor: "#253238",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="mb-1 pt-10" style={{ color: "white", width: "70%" }}>
              {localStorage.getItem("noAreaMessage")}
            </p>
            <button
              type="submit"
              className="btn btn-update-location  br-12 f-14 no-alert mt-20 "
              onClick={() => {
                this.context.router.history.push("/search-location");
              }}
            >
              {localStorage.getItem("userSetAddress") && (
                <React.Fragment>Update Location</React.Fragment>
              )}
              <Ink duration="500" />
            </button>
          </div>
          .
        </div>
      </React.Fragment>
    );
  }
}

export default NoAreaAlert;
