import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";

import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazyload";

import { withRouter } from "react-router-dom";
// import Customization from "../../CategoryItemsV1/Customization";
import FloatCart from "../../FloatCart";
import BackWithSearch from "../../Elements/BackWithSearch";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import { getRestaurantsFeaturedItems } from "../../../../services/restaurant/actions";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import Variations from "../../CategoryItemsV1/Variations";
import Customization from "../../Items/Customization";
import { setFavoriteItem } from "../../../../services/items/actions";

class FrequentlyBoughtList extends Component {
  state = {
    update: true,
    total: null,
    restaurants_featured_items: [],
    loading: false,
    loading_more: true,
    no_restaurants_featured_items: false,
  };

  componentDidMount() {
    this.__getRestaurantsFeaturedItems();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state.data) {
      return true;
    } else {
      return false;
    }
  }

  __getRestaurantsFeaturedItems = () => {
    if (localStorage.getItem("userSetAddress")) {
      this.setState({
        loading: true,
      });
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getRestaurantsFeaturedItems(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants_featured_items) => {
          if (
            restaurants_featured_items &&
            restaurants_featured_items.payload.length
          ) {
            this.setState({
              total: restaurants_featured_items.payload.length,
              no_restaurants_featured_items: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              no_restaurants_featured_items: true,
            });
          }
        });
    }
  };

  forceStateUpdate = () => {
    this.forceUpdate();
    const restaurant = this.props.match.params.restaurant;

    if (restaurant !== "all") {
      this.props.forceStateUpdate();
    }
    setTimeout(() => {
      if (this.state.update) {
        this.setState({ update: false });
      } else {
        this.setState({ update: true });
      }
    }, 100);
  };

  __getSalePrice = (item_variants) => {
    var variant = item_variants.find(function(o) {
      return o.is_default === 1;
    });
    if (variant && variant !== undefined && variant.sale_price !== undefined) {
      let sale_price = variant.sale_price;
      return parseFloat(sale_price).toFixed(0);
    }
    return 0;
  };

  __getRegularPrice = (item_variants) => {
    var variant = item_variants.find(function(o) {
      return o.is_default === 1;
    });
    if (
      variant &&
      variant !== undefined &&
      variant.regular_price !== undefined
    ) {
      let sale_price = variant.sale_price;
      let regular_price = variant.regular_price;
      if (sale_price !== regular_price) {
        return parseFloat(regular_price).toFixed(0);
      }
    }
    return false;
  };

  __getDiscountPercentage = (item) => {
    var discount = 0;
    if (item.has_variant) {
      let sale_price = this.__getSalePrice(item.variants);
      let regular_price = this.__getRegularPrice(item.variants);
      if (parseFloat(sale_price) >= parseFloat(regular_price)) {
        return false;
      }
      if (regular_price === false) {
        discount = 0;
      } else {
        discount =
          ((parseFloat(regular_price) - parseFloat(sale_price)) /
            parseFloat(regular_price)) *
          100;
      }
    }

    return discount !== 0 ? discount.toFixed(0) : false;
  };

  setFavoriteItem = (single_item, event) => {
    const { user } = this.props;
    if (user.success) {
      event.target.classList.toggle("is-active");
      this.props
        .setFavoriteItem(user.data.auth_token, single_item.id)
        .then((res) => this.props.updateRestaurantItemsState(true));
    }
  };

  __formatPrice = (price) => {
    return parseFloat(price).toFixed(0);
  };

  __itemAvailability = (item) => {
    if (!item.has_variant) return true;

    if (item.variant_type === "P") {
      let is_item_out_of_stock = item.variants.filter((variant) => {
        return (
          variant.is_default &&
          variant.stock <= 0 &&
          !variant.is_infinite &&
          !variant.is_negative
        );
      });
      return is_item_out_of_stock.length ? false : true;
    } else {
      let is_item_out_of_stock = item.variants.filter((variant) => {
        return (
          variant.is_default && (variant.is_infinite || variant.is_negative)
        );
      });
      if (is_item_out_of_stock.length > 0) {
        return true;
      }
      return item.variant_stock > 0;
    }
  };

  render() {
    const {
      addProduct,
      removeProduct,
      cartProducts,
      forceRestaurantItemsUpdate,
    } = this.props;

    const isItemExistInCart = (item_id) => {
      let cc = cartProducts.find((cartItem) => {
        return cartItem.id === item_id;
      });
      if (cc !== undefined) {
        return true;
      }
      return false;
    };

    const { history, user } = this.props;
    const restaurant = this.props.match.params.restaurant;

    if (restaurant !== "all") {
      this.props.forceStateUpdate();
    }

    if (forceRestaurantItemsUpdate === true) {
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getRestaurantsFeaturedItems(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants_featured_items) => {
          if (
            restaurants_featured_items &&
            restaurants_featured_items.payload.length
          ) {
            this.setState({
              total: restaurants_featured_items.payload.length,
              no_restaurants_featured_items: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              no_restaurants_featured_items: true,
            });
          }
        });
      this.props.updateRestaurantItemsState(false);
    }

    return (
      <React.Fragment>
        <div className="bg-white">
          {restaurant !== undefined ? (
            <React.Fragment>
              <BackWithSearch
                ref={(node) => {
                  this.child = node;
                }}
                history={history}
                boxshadow={false}
                has_restaurant_info={true}
                restaurant={restaurant}
                disable_search={true}
                homeButton={true}
                shareButton={true}
              />
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div
          className={
            "col-sm-12 bg-white mb-20" +
            (restaurant !== undefined ? " pt-50" : "")
          }
        >
          {this.state.loading ? (
            <ContentLoader
              height={378}
              width={400}
              speed={1.2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
              <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
              <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

              <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
              <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
              <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

              <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
              <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
              <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
            </ContentLoader>
          ) : (
            <React.Fragment>
              <div className="frequently-bought-title-section mt-20 my-10">
                <span className="title">
                  <strong>
                    {localStorage.getItem("frequentlyBoughtSectionTitle")}
                  </strong>
                </span>
                <span className="subtitle">
                  {localStorage.getItem("frequentlyBoughtSectionSubTitle")}
                </span>

                {restaurant !== undefined ? (
                  <React.Fragment />
                ) : (
                  <DelayLink
                    to={"frequently-bought-items/all"}
                    delay={200}
                    className="block text-center mb-3 view-all"
                  >
                    View All
                    <i className="si si-arrow-right nav-location-icon ml-1" />
                  </DelayLink>
                )}
              </div>
              <div className="frequently-bought-content-section">
                {this.props.restaurants_featured_items.length === 0 ? (
                  <ContentLoader
                    height={378}
                    width={400}
                    speed={1.2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                  >
                    <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                    <rect
                      x="144"
                      y="30"
                      rx="0"
                      ry="0"
                      width="115"
                      height="18"
                    />
                    <rect
                      x="144"
                      y="60"
                      rx="0"
                      ry="0"
                      width="165"
                      height="16"
                    />

                    <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                    <rect
                      x="144"
                      y="155"
                      rx="0"
                      ry="0"
                      width="115"
                      height="18"
                    />
                    <rect
                      x="144"
                      y="185"
                      rx="0"
                      ry="0"
                      width="165"
                      height="16"
                    />

                    <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                    <rect
                      x="144"
                      y="280"
                      rx="0"
                      ry="0"
                      width="115"
                      height="18"
                    />
                    <rect
                      x="144"
                      y="310"
                      rx="0"
                      ry="0"
                      width="165"
                      height="16"
                    />
                  </ContentLoader>
                ) : (
                  <React.Fragment>
                    {this.props.restaurants_featured_items.map((item) => (
                      <React.Fragment key={item.id}>
                        <div
                          className="grey-border br-8  mb-10"
                          style={{
                            position: "relative",
                            boxShadow: " 2px 2px 8px 0px #0000000A",
                          }}
                        >
                          {!this.__itemAvailability(item) && (
                            <div className="blur-content-list">
                              {localStorage.getItem("outOfStockText")}
                            </div>
                          )}
                          <div
                            className={`p-10 category-list-item ${
                              !this.__itemAvailability(item)
                                ? "blur-content-section"
                                : ""
                            }`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {this.__getDiscountPercentage(item) ? (
                              <div
                                className="item-discount-tag"
                                style={{ top: "-5px", left: "5px" }}
                              >
                                <img
                                  style={{ width: "65px" }}
                                  alt="item-discount"
                                  src="../../assets/img/clip-path.svg"
                                />
                                <span
                                  className="discount-price"
                                  style={{ width: "65px", top: "5px" }}
                                >
                                  {this.__getDiscountPercentage(item)
                                    ? this.__getDiscountPercentage(item)
                                    : 0}
                                  % OFF
                                </span>
                              </div>
                            ) : (
                              <React.Fragment />
                            )}
                            {item.image !== null && (
                              <React.Fragment>
                                <Link
                                  to={
                                    "../stores/" +
                                    item.restaurant_slug +
                                    "/" +
                                    item.id
                                  }
                                >
                                  <React.Fragment>
                                    {this.state.searching ? (
                                      <img
                                        src={item.image}
                                        alt={item.name}
                                        style={{ width: "65px" }}
                                      />
                                    ) : (
                                      <LazyLoad>
                                        <ProgressiveImage
                                          src={item.image}
                                          placeholder="/assets/img/various/blank-white.jpg"
                                        >
                                          {(src, loading) => (
                                            <img
                                              style={{
                                                opacity: loading ? "0.5" : "1",
                                                width: "75px",
                                                height: "75px",
                                                marginRight: "10px",
                                              }}
                                              className="object-fit "
                                              src={src}
                                              alt={item.name}
                                            />
                                          )}
                                        </ProgressiveImage>
                                      </LazyLoad>
                                    )}
                                  </React.Fragment>
                                </Link>
                              </React.Fragment>
                            )}
                            <Link
                              className="flex-item-name "
                              to={
                                "../stores/" +
                                item.restaurant_slug +
                                "/" +
                                item.id
                              }
                            >
                              <div className={"flex-item-name"}>
                                <span className="item-name single-line">
                                  {item.name}
                                </span>{" "}
                                {item.has_variant ? (
                                  <span
                                    className="item-subtitle text-normal"
                                    style={{
                                      visibility: "hidden",
                                    }}
                                  >
                                    {
                                      item.variants.find(function(o) {
                                        return o.is_default === 1;
                                      }).name
                                    }
                                  </span>
                                ) : (
                                  <span className="item-subtitle text-normal">
                                    &nbsp;
                                  </span>
                                )}
                                {/* <ItemBadge item={item} /> */}
                                <div className="item-color-price font-w600 text-left ">
                                  {item.has_variant ? (
                                    <React.Fragment>
                                      <span className="sale-price text-black">
                                        {localStorage.getItem(
                                          "currencySymbolAlign"
                                        ) === "left" &&
                                          localStorage.getItem(
                                            "currencyFormat"
                                          )}{" "}
                                        {this.__getSalePrice(item.variants)}
                                      </span>
                                      {this.__getRegularPrice(
                                        item.variants
                                      ) && (
                                        <span className="regular-price ml-1">
                                          {localStorage.getItem(
                                            "currencySymbolAlign"
                                          ) === "left" &&
                                            localStorage.getItem(
                                              "currencyFormat"
                                            )}
                                          <s>
                                            {this.__getRegularPrice(
                                              item.variants
                                            )}
                                          </s>
                                        </span>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <span className="sale-price">
                                      {localStorage.getItem(
                                        "currencySymbolAlign"
                                      ) === "left" &&
                                        localStorage.getItem(
                                          "currencyFormat"
                                        )}{" "}
                                      {this.__formatPrice(item.price)}
                                      {localStorage.getItem(
                                        "currencySymbolAlign"
                                      ) === "right" &&
                                        localStorage.getItem("currencyFormat")}
                                    </span>
                                  )}
                                </div>
                                <div className="inclusive-tax-text">
                                  {localStorage.getItem("inclusiveTaxText")}
                                </div>
                              </div>
                            </Link>

                            <div className="item-actions pull-right pb-0 ">
                              {/* {user.success && (
                                <span
                                  onClick={(event) =>
                                    this.setFavoriteItem(item, event)
                                  }
                                  className={
                                    `item-favorite text-right heart ${item.is_favorited &&
                                      "is-active"} heartIcon` + item.id
                                  }
                                />
                              )} */}
                              {item.is_active ? (
                                <React.Fragment>
                                  {isItemExistInCart(item.id) ? (
                                    <React.Fragment>
                                      {item.has_variant &&
                                      item.variants.length > 0 ? (
                                        <Variations
                                          product={item}
                                          addProduct={addProduct}
                                          cartProducts={cartProducts}
                                          forceUpdate={this.forceStateUpdate}
                                          addByDefault={
                                            item.variants.length === 1
                                              ? true
                                              : false
                                          }
                                          showDefaultVariant={true}
                                        />
                                      ) : (
                                        <React.Fragment>
                                          <div
                                            className="btn-group btn-group-sm"
                                            role="group"
                                            aria-label="btnGroupIcons1"
                                          >
                                            {item.addon_categories.length ? (
                                              <button
                                                disabled
                                                type="button"
                                                className="btn btn-add-remove"
                                                style={{
                                                  color: localStorage.getItem(
                                                    "cartColor-bg"
                                                  ),
                                                }}
                                              >
                                                <span className="btn-dec">
                                                  -
                                                </span>
                                                <Ink duration="500" />
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="btn btn-add-remove"
                                                style={{
                                                  color: localStorage.getItem(
                                                    "cartColor-bg"
                                                  ),
                                                }}
                                                onClick={() => {
                                                  item.quantity = 1;
                                                  removeProduct(item);
                                                  this.forceStateUpdate();
                                                }}
                                              >
                                                <span className="btn-dec">
                                                  -
                                                </span>
                                                <Ink duration="500" />
                                              </button>
                                            )}

                                            <input
                                              type="text"
                                              className="textbox-qty"
                                              disabled
                                              value={
                                                cartProducts.find(
                                                  (cp) => cp.id === item.id
                                                ).quantity
                                              }
                                            />

                                            {item.addon_categories.length ? (
                                              <Customization
                                                product={item}
                                                addProduct={addProduct}
                                                forceUpdate={
                                                  this.forceStateUpdate
                                                }
                                              />
                                            ) : (
                                              <button
                                                type="button"
                                                className="btn btn-add-remove"
                                                style={{
                                                  color: localStorage.getItem(
                                                    "cartColor-bg"
                                                  ),
                                                }}
                                                onClick={() => {
                                                  addProduct(item);
                                                  this.forceStateUpdate();
                                                }}
                                              >
                                                <span className="btn-inc">
                                                  +
                                                </span>
                                                <Ink duration="500" />
                                              </button>
                                            )}
                                          </div>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {item.has_variant &&
                                      item.variants.length > 0 ? (
                                        <Variations
                                          product={item}
                                          addProduct={addProduct}
                                          cartProducts={cartProducts}
                                          forceUpdate={this.forceStateUpdate}
                                          addByDefault={
                                            item.variants.length === 1
                                              ? true
                                              : false
                                          }
                                          showDefaultVariant={true}
                                        />
                                      ) : (
                                        <React.Fragment>
                                          <div
                                            className="btn-group btn-group-sm"
                                            role="group"
                                            aria-label="btnGroupIcons1"
                                          >
                                            {item.addon_categories.length ? (
                                              <Customization
                                                product={item}
                                                addProduct={addProduct}
                                                forceUpdate={
                                                  this.forceStateUpdate
                                                }
                                              />
                                            ) : (
                                              <button
                                                type="button"
                                                className="btn btn-add-remove btn-custom-add"
                                                style={{
                                                  color: localStorage.getItem(
                                                    "cartColor-bg"
                                                  ),
                                                }}
                                                onClick={() => {
                                                  addProduct(item);
                                                  this.forceStateUpdate();
                                                }}
                                              >
                                                Add
                                                <Ink duration="500" />
                                              </button>
                                            )}
                                          </div>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              ) : (
                                <div className="text-danger text-item-not-available">
                                  {localStorage.getItem("cartItemNotAvailable")}
                                </div>
                              )}

                              {item.addon_categories.length > 0 && (
                                <React.Fragment>
                                  <span
                                    className="customizable-item-text d-block text-center"
                                    style={{
                                      color: localStorage.getItem("storeColor"),
                                    }}
                                  >
                                    {localStorage.getItem(
                                      "customizableItemText"
                                    )}
                                  </span>
                                  <br />
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}

          <div>
            {this.props.match.params.restaurant === "all" ? (
              <React.Fragment>
                {!this.state.loading && (
                  <React.Fragment>
                    <div className="deal-of-the-day-home-screen">
                      <FloatCart forceUpdate={this.forceStateUpdate} />
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </div>

          {this.state.loading_more ? (
            <div className="">
              <ContentLoader
                height={120}
                width={400}
                speed={1.2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
              </ContentLoader>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
  restaurants_featured_items: state.restaurant.restaurants_featured_items,
  cartProducts: state.cart.products,
  user: state.user.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getRestaurantsFeaturedItems,
      addProduct,
      removeProduct,
      setFavoriteItem,
    }
  )(FrequentlyBoughtList)
);
