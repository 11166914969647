import * as firebase from "firebase/app";

import React, { Component } from "react";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";
import { Redirect } from "react-router";
// import RestaurantList from "./RestaurantList";
import CategoryList from "./CategoryList";
import DealOfTheDayList from "./DealOfTheDayList";
import { connect } from "react-redux";
import { getPromoSlides } from "../../../services/promoSlider/actions";
import { getDynamicFeaturedSections } from "../../../services/dynamicFeaturedSection/actions";
import { GET_ADDRESS_FROM_COORDINATES } from "../../../configs";

import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import { getUserNotifications } from "../../../services/alert/actions";
import {
  resetInfo,
  resetItems,
  resetBackup,
} from "../../../services/items/actions";
import Ink from "react-ink";

import { Link } from "react-router-dom";
import NoAreaAlert from "./NoAreaAlert";
import Combos from "./Combos";
import DynamicFeaturedSectionStyle1 from "./DynamicFeaturedSectionStyle1";
import DynamicFeaturedSectionStyle2 from "./DynamicFeaturedSectionStyle2";
import Axios from "axios";
// import moment from "moment";
const sizeClasses = {
  5: "size-xlarge",
  4: "size-large",
  3: "size-medium",
  2: "size-small",
  1: "size-xsmall",
};

class HomeV1 extends Component {
  state = {
    update: true,
    no_area: false,
    restaurant_items_update: false,
    noSetAddress: true,
    gps_loading: false,
  };

  static contextTypes = {
    router: () => null,
  };

  async componentDidMount() {
    let A = 24;
    let count = 0;
    for (let i = 1; i * i <= A; i++) {
      if (i * i === A) {
        count += 1;
      } else if (A % i === 0) {
        count += 2;
      }
    }
    this.props.resetItems();
    this.props.resetInfo();
    this.props.resetBackup();

    const { user } = this.props;

    //if currentLocation doesnt exists in localstorage then redirect the user to firstscreen
    //else make API calls
    if (localStorage.getItem("userSetAddress") !== null) {
      // this.context.router.history.push("/search-location");
      // console.log("Redirect to search location");
      // return <Redirect to="/search-location" />;
      if (localStorage.getItem("showPromoSlider") === "true") {
        const userSetAddress = JSON.parse(
          localStorage.getItem("userSetAddress")
        );

        this.props.getDynamicFeaturedSections(
          userSetAddress.lat,
          userSetAddress.lng
        );
        this.props.getPromoSlides(userSetAddress.lat, userSetAddress.lng);
      }

      const { user } = this.props;

      if (user.success) {
        this.props.getUserNotifications(user.data.id, user.data.auth_token);
      }
      this.setState({ noSetAddress: false });
    } else {
      this.setState({ noSetAddress: true });
    }

    if (user.success) {
      if (localStorage.getItem("enablePushNotification") === "true") {
        if (firebase.messaging.isSupported()) {
          // const today = moment().toDate();

          // console.log("TODAY", today);
          // const lastSavedNotificationToken = moment(localStorage.getItem("lastSavedNotificationToken"));
          // const days = moment(today).diff(lastSavedNotificationToken, "days");

          // console.log("DAYS", days);

          // const callForNotificationToken = isNaN(days) || days >= 5;

          // console.log(callForNotificationToken);
          // if (callForNotificationToken) {
          let handler = this.props.saveNotificationToken;
          messaging
            .requestPermission()
            .then(async function() {
              const push_token = await messaging.getToken();
              handler(push_token, user.data.id, user.data.auth_token);
              // localStorage.setItem("lastSavedNotificationToken", today);
            })
            .catch(function(err) {
              console.log("Unable to get permission to notify.", err);
            });
          // }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (localStorage.getItem("userPreferedLanguage")) {
        this.props.getSingleLanguageData(
          localStorage.getItem("userPreferedLanguage")
        );
      } else {
        if (nextProps.languages.length) {
          // console.log("Fetching Translation Data...");
          const id = nextProps.languages.filter(
            (lang) => lang.is_default === 1
          )[0].id;
          this.props.getSingleLanguageData(id);
        }
      }
    }
  }

  componentWillUnmount() {
    // navigator.serviceWorker.removeEventListener("message", message => console.log(message));
  }

  getMyLocation = () => {
    const location = navigator && navigator.geolocation;
    this.setState({ gps_loading: true });
    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.reverseLookup(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          this.setState({ gps_loading: false });
          console.log(error);
          alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
        },
        { timeout: 5000 }
      );
    }
  };
  reverseLookup = (lat, lng) => {
    Axios.post(GET_ADDRESS_FROM_COORDINATES, {
      lat: lat,
      lng: lng,
    })
      .then((response) => {
        const myLocation = [
          {
            formatted_address: response.data,
            geometry: {
              location: {
                lat: lat,
                lng: lng,
              },
            },
          },
        ];
        this.handleGeoLocationClick(myLocation);
      })
      .catch(function(error) {
        console.warn(error.response.data);
      });
  };
  handleGeoLocationClick = (results) => {
    // console.log(results);
    const saveGeoLocation = new Promise((resolve) => {
      localStorage.setItem("geoLocation", JSON.stringify(results[0]));
      resolve("GeoLocation Saved");
    });
    saveGeoLocation.then(() => {
      this.setState({ gps_loading: false, noSetAddress: false });
      this.context.router.history.push("/my-location");
    });
  };

  noAreaAlert = (no_area) => {
    this.setState({ no_area: no_area });
  };

  forceStateUpdate = () => {
    if (this.state.update) {
      this.setState({ update: false });
    } else {
      this.setState({ update: true });
    }

    return this.state.update;
  };

  updateRestaurantItemsState = (action) => {
    this.setState({ restaurant_items_update: action });
  };

  render() {
    if (window.innerWidth > 768) {
      return <Redirect to="/" />;
    }

    if (localStorage.getItem("userSetAddress") === null) {
      // this.context.router.history.push("/search-location");
      // console.log("Redirect to search location");
      // return <Redirect to="/search-location" />;
    }

    const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
    if (userSetAddress) {
      if (
        Object.keys(userSetAddress).length === 0 &&
        userSetAddress.constructor === Object
      ) {
        return <Redirect to="/search-location" />;
      }
    }

    const {
      history,
      user,
      promo_slides,
      dynamic_featured_sections,
    } = this.props;
    // console.log(promo_slides);

    //mains -

    //others

    const afterCategorySliders =
      promo_slides.otherSlides !== undefined
        ? promo_slides.otherSlides.filter(
            (slide) => slide.promo_slider.position_id === 1
          )
        : [];

    const afterDealofthedaySliders =
      promo_slides.otherSlides !== undefined
        ? promo_slides.otherSlides.filter(
            (slide) => slide.promo_slider.position_id === 2
          )
        : [];

    const afterFrequentlyBoughtSliders =
      promo_slides.otherSlides !== undefined
        ? promo_slides.otherSlides.filter(
            (slide) => slide.promo_slider.position_id === 3
          )
        : [];

    return (
      <div className="position-relative">
        <Meta
          seotitle={localStorage.getItem("seoMetaTitle")}
          seodescription={localStorage.getItem("seoMetaDescription")}
          ogtype="website"
          ogtitle={localStorage.getItem("seoOgTitle")}
          ogdescription={localStorage.getItem("seoOgDescription")}
          ogurl={window.location.href}
          twittertitle={localStorage.getItem("seoTwitterTitle")}
          twitterdescription={localStorage.getItem("seoTwitterDescription")}
        />
        <div className="height-100-percent bg-white position-relative">
          <div className="">
            <Nav
              logo={true}
              active_nearme={true}
              disable_back_button={true}
              history={history}
              loggedin={user.success}
            />
            {!this.state.no_area && (
              <>
                {" "}
                {localStorage.getItem("mockSearchOnHomepage") === "true" && (
                  <Link to="explore" className="position-absolute w-full">
                    <div
                      className={`mock-search-block px-15 ${
                        localStorage.getItem("showPromoSlider") === "false"
                          ? "pt-0"
                          : "" + promo_slides.mainSlides === "null"
                          ? "pt-0"
                          : ""
                      }`}
                    >
                      <div
                        className="px-15 d-flex"
                        style={{ backgroundColor: "white !important" }}
                      >
                        <div>
                          <img
                            src={"./assets/img/home/search-icon.png"}
                            alt="search-location"
                          />
                        </div>
                        <div>
                          <span>
                            {localStorage.getItem("mockSearchPlaceholder")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </>
            )}
            <img
              src={"https://knaturals.co.in/assets/img/Hero.jpg"}
              alt=""
              className="w-full"
              style={{
                marginTop: "-85px",
                borderBottomRightRadius: "18px",
                borderBottomLeftRadius: "18px",
              }}
            />
          </div>
          {this.state.no_area ? (
            <NoAreaAlert />
          ) : (
            <React.Fragment>
              {localStorage.getItem("customHomeMessage") !== "<p><br></p>" &&
                localStorage.getItem("customHomeMessage") !== "null" &&
                (localStorage.getItem("customHomeMessage") !== "" && (
                  <div
                    style={{
                      position: "relative",
                      background: "#f8f9fa",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: localStorage.getItem("customHomeMessage"),
                    }}
                  />
                ))}
              {/* <RestaurantList user={user} slides={promo_slides.otherSlides} /> */}

              {/* Passing slides as props to PromoSlider */}

              {/*main slides*/}
              {localStorage.getItem("showPromoSlider") === "true" && (
                <>
                  <label className="px-15 mb-0 f-16 fw600">
                    {promo_slides.mainSlides &&
                      promo_slides.mainSlides.length &&
                      promo_slides.mainSlides[0].data.name}
                  </label>
                  <div
                    className="px-15 pt-10 pb-0 flex"
                    style={{
                      columnGap: "15px",
                      overflowX: "scroll",
                      overflowY: "hidden",
                    }}
                  >
                    {promo_slides &&
                      promo_slides.mainSlides &&
                      promo_slides.mainSlides.length > 0 && (
                        <>
                          {promo_slides.mainSlides.map((slide, key) => {
                            return (
                              <a href={slide.url} key={key}>
                                <div
                                  className={`${
                                    sizeClasses[slide.promo_slider.size]
                                  }`}
                                >
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    alt={slide.data.name}
                                    src={
                                      slide.data.image ? slide.data.image : ""
                                    }
                                  />
                                </div>
                              </a>
                            );
                          })}
                        </>
                      )}
                  </div>
                </>
              )}

              {dynamic_featured_sections &&
                dynamic_featured_sections !== undefined && (
                  <React.Fragment>
                    {dynamic_featured_sections.main_section !== undefined &&
                      dynamic_featured_sections.main_section.map((section) => (
                        <React.Fragment key={section.id}>
                          {section.style == "style_1" && (
                            <DynamicFeaturedSectionStyle1
                              forceStateUpdate={this.forceStateUpdate}
                              featured_section={section}
                              section_type="main"
                              scroll="horizontal"
                            />
                          )}
                          {section.style == "style_2" && (
                            <DynamicFeaturedSectionStyle1
                              forceStateUpdate={this.forceStateUpdate}
                              featured_section={section}
                              section_type="main"
                              scroll="horizontal"
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                )}

              <CategoryList
                noAreaAlert={this.noAreaAlert}
                user={user}
                slides={promo_slides.otherSlides}
              />

              {/*after category slides*/}
              {localStorage.getItem("showPromoSlider") === "true" && (
                <div
                  className="px-15 pt-20 pb-0 flex"
                  style={{
                    columnGap: "15px",
                    overflowX: "scroll",
                    overflowY: "hidden",
                  }}
                >
                  {afterCategorySliders &&
                    afterCategorySliders &&
                    afterCategorySliders.length > 0 && (
                      <>
                        {afterCategorySliders.map((slide, key) => {
                          return (
                            <a
                              href={slide.url}
                              key={key}
                              style={{ width: "100%", height: "100%" }}
                            >
                              <div
                                className={`${
                                  sizeClasses[slide.promo_slider.size]
                                }`}
                              >
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  alt={slide.data.image}
                                  src={slide.data.image}
                                />
                              </div>
                            </a>
                          );
                        })}
                      </>
                    )}
                </div>
              )}

              {dynamic_featured_sections &&
                dynamic_featured_sections !== undefined && (
                  <React.Fragment>
                    {dynamic_featured_sections.category_section !== undefined &&
                      dynamic_featured_sections.category_section.map(
                        (section) => (
                          <React.Fragment key={section.id}>
                            {section.style == "style_1" && (
                              <DynamicFeaturedSectionStyle1
                                forceStateUpdate={this.forceStateUpdate}
                                featured_section={section}
                                section_type="category"
                                scroll="horizontal"
                              />
                            )}
                            {section.style == "style_2" && (
                              <DynamicFeaturedSectionStyle1
                                forceStateUpdate={this.forceStateUpdate}
                                featured_section={section}
                                section_type="category"
                                scroll="horizontal"
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                  </React.Fragment>
                )}

              {/* {localStorage.getItem("showPromoSlider") === "true" && (
                <React.Fragment>
                  {afterCategorySliders && afterCategorySliders.length > 0 && (
                    <CarouselSlider
                      slides={afterCategorySliders}
                      size={afterCategorySliders[0]["promo_slider"]["size"]}
                      show_indicators={
                        promo_slides.mainSlides[0]["promo_slider"][
                          "show_indicators"
                        ]
                      }
                      infinite_loop={
                        promo_slides.mainSlides[0]["promo_slider"][
                          "infinite_loop"
                        ]
                      }
                      auto_play={
                        promo_slides.mainSlides[0]["promo_slider"]["auto_play"]
                      }
                      secondarySlider={true}
                    />
                  )}
                </React.Fragment>
              )} */}

              <DealOfTheDayList
                updateRestaurantItemsState={this.updateRestaurantItemsState}
                forceRestaurantItemsUpdate={this.state.restaurant_items_update}
                forceStateUpdate={this.forceStateUpdate}
                user={user}
                slides={promo_slides.otherSlides}
              />

              {dynamic_featured_sections &&
                dynamic_featured_sections !== undefined && (
                  <React.Fragment>
                    {dynamic_featured_sections.deal_of_the_day_section !==
                      undefined &&
                      dynamic_featured_sections.deal_of_the_day_section.map(
                        (section) => (
                          <React.Fragment key={section.id}>
                            {section.style == "style_1" && (
                              <DynamicFeaturedSectionStyle1
                                forceStateUpdate={this.forceStateUpdate}
                                featured_section={section}
                                section_type="deal_of_the_day"
                                scroll="horizontal"
                              />
                            )}
                            {section.style == "style_2" && (
                              <DynamicFeaturedSectionStyle1
                                forceStateUpdate={this.forceStateUpdate}
                                featured_section={section}
                                section_type="deal_of_the_day"
                                scroll="horizontal"
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                  </React.Fragment>
                )}

              {/*after deal of the day slides*/}
              {localStorage.getItem("showPromoSlider") === "true" &&
                afterDealofthedaySliders.length > 0 && (
                  <div
                    className="px-15 pt-20 pb-0 flex"
                    style={{
                      columnGap: "15px",
                      overflowX: "scroll",
                      overflowY: "hidden",
                    }}
                  >
                    {afterDealofthedaySliders &&
                      afterDealofthedaySliders &&
                      afterDealofthedaySliders.length > 0 && (
                        <>
                          {afterDealofthedaySliders.map((slide, key) => {
                            return (
                              <a
                                href={slide.url}
                                key={key}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div
                                  className={`${
                                    sizeClasses[slide.promo_slider.size]
                                  }`}
                                >
                                  {" "}
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    alt={slide.data.name}
                                    src={slide.data.image}
                                  />
                                </div>
                              </a>
                            );
                          })}
                        </>
                      )}
                  </div>
                )}

              {this.state.noSetAddress && (
                <div
                  style={{
                    position: "fixed",
                    zIndex: 100,
                    top: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    height: "100vh",
                    width: "calc(100vw + 1px)",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="text-white mb-10"
                    style={{
                      marginTop: "40px",
                      fontWeight: 600,
                      marginLeft: "calc(100% - 140px)",
                    }}
                  >
                    Deliver To
                  </p>
                  <div className="triangle" />
                  <div
                    className="bg-white br-8 p-15"
                    style={{
                      width: "80%",
                      height: "fit-content",
                      opacity: "1",
                      alignContent: "center",
                    }}
                  >
                    <h5 className="f-16 fw600 mb-5 text-center">
                      Select your location
                    </h5>
                    <p className=" grey-color text-center f-12 mb-15">
                      We need yout location to show you curated assortment from
                      your nearest store.
                    </p>
                    <div
                      className="flex mt-5"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={() => {
                          this.getMyLocation();
                        }}
                        className="mr-10 px-15 py-10 br-8 f-12 "
                        style={{
                          border: "0",
                          background: "#318616",
                          color: "white",
                          outline: "none",
                          cursor: "pointer",
                        }}
                      >
                        {this.state.gps_loading
                          ? "Fetching Location"
                          : "Use my Location"}
                      </button>

                      <button
                        onClick={() => {
                          this.context.router.history.push("/search-location");
                        }}
                        className="px-15 py-10  bg-white green-color br-8 f-12"
                        style={{
                          border: "1px solid  #318616",
                          outline: "none",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        Select manually
                      </button>
                    </div>
                    <p className="mt-10 mb-0" style={{ textAlign: "center" }}>
                      Go to{" "}
                      <span
                        className="green-color"
                        onClick={() => {
                          this.context.router.history.push("/login");
                        }}
                      >
                        Login
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {this.state.gps_loading && (
                <div className="height-100 overlay-loading ongoing-payment-spin">
                  <div className="spin-load" />
                </div>
              )}
              <Combos
                updateRestaurantItemsState={this.updateRestaurantItemsState}
                forceRestaurantItemsUpdate={this.state.restaurant_items_update}
                forceStateUpdate={this.forceStateUpdate}
                user={user}
                slides={promo_slides.otherSlides}
              />

              {/* <FrequentlyBoughtList
                updateRestaurantItemsState={this.updatefmRestaurantItemsState}
                forceRestaurantItemsUpdate={this.state.restaurant_items_update}
                forceStateUpdate={this.forceStateUpdate}
                user={user}
                slides={promo_slides.otherSlides}
              /> */}
              {dynamic_featured_sections &&
                dynamic_featured_sections !== undefined && (
                  <React.Fragment>
                    {dynamic_featured_sections.frequenty_bought_section !==
                      undefined &&
                      dynamic_featured_sections.frequenty_bought_section.map(
                        (section) => (
                          <React.Fragment key={section.id}>
                            {section.style == "style_1" && (
                              <DynamicFeaturedSectionStyle1
                                forceStateUpdate={this.forceStateUpdate}
                                featured_section={section}
                                section_type="frequently_bought"
                                scroll="horizontal"
                              />
                            )}
                            {section.style == "style_2" && (
                              <DynamicFeaturedSectionStyle2
                                forceStateUpdate={this.forceStateUpdate}
                                featured_section={section}
                                section_type="frequently_bought"
                                scroll="horizontal"
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                  </React.Fragment>
                )}

              {localStorage.getItem("showPromoSlider") === "true" && (
                <div
                  style={{
                    backgroundColor: "rgb(108, 189, 223)",
                    textAlign: "center",
                  }}
                  className="mt-10"
                >
                  <img
                    style={{
                      width: "100%",
                    }}
                    src="https://instamart-media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,h_474,w_936/rng/md/carousel/production/5be8674bb40e9125ae64bffc1359f426"
                    alt=""
                  />
                  <div
                    className="px-15 pt-20 pb-0 flex"
                    style={{
                      columnGap: "15px",
                      overflowX: "scroll",
                      overflowY: "hidden",
                    }}
                  >
                    {afterFrequentlyBoughtSliders &&
                      afterFrequentlyBoughtSliders.length > 0 &&
                      afterFrequentlyBoughtSliders.map((slide, key) => {
                        return (
                          <a href={slide.url} key={key}>
                            <div
                              className={`${
                                sizeClasses[slide.promo_slider.size]
                              }`}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                alt={slide.data.name}
                                src={slide.data.image}
                              />
                            </div>
                          </a>
                        );
                      })}
                  </div>
                  <button
                    className="p-15 mb-20 br-8 f-16 mt-10"
                    style={{
                      width: "85%",
                      border: "none",
                    }}
                  >
                    Shop More
                  </button>
                </div>
              )}

              {/* <CarouselSlider
                          slides={afterFrequentlyBoughtSliders}
                          size={afterFrequentlyBoughtSliders[0]["promo_slider"]["size"]}
                          show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                          infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                          auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                          secondarySlider={true} /> */}
              <img
                className="mb-50"
                width="100%"
                style={{
                  padding: "25px 0px 13px 0px ",
                }}
                alt="test"
                src="./assets/img/food-feel-good.png"
              />

              <Footer active_nearme={true} />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  promo_slides: state.promo_slides.promo_slides,
  dynamic_featured_sections:
    state.dynamic_featured_sections.dynamic_featured_sections,
  user: state.user.user,
  locations: state.locations.locations,
  languages: state.languages.languages,
});

export default connect(
  mapStateToProps,
  {
    getPromoSlides,
    getDynamicFeaturedSections,
    saveNotificationToken,
    getSingleLanguageData,
    getUserNotifications,
    resetInfo,
    resetItems,
    resetBackup,
  }
)(HomeV1);
