import React, { Component } from "react";

import Ink from "react-ink";

import Modal from "react-responsive-modal";
import { connect } from "react-redux";

// import FloatCart from "../../FloatCart";
import { addProduct, removeProduct } from "../../../../services/cart/actions";

class Variations extends Component {
  state = {
    update: true,
    open: false,
  };

  handlePopupOpen = () => {
    console.log("here");
    if (this.state.open) {
      this.handlePopupClose();
    }
    this.setState({ open: true });
  };

  forceStateUpdate = () => {
    this.props.forceUpdate();
    setTimeout(() => {
      if (this.state.update) {
        this.setState({ update: false });
      } else {
        this.setState({ update: true });
      }
    }, 100);
  };

  addProductVariant = (product, variant) => {
    if (variant) {
      product.selectedvariant = variant;
      // product.name += variant.name;
      product.price = variant.sale_price;
    }
    product.quantity = 1;
    console.log(product);
    this.props.addProduct(product);
  };

  handlePopupClose = () => {
    this.setState({ open: false });
    // this.props.forceUpdate();
  };

  __formatPrice = (price) => {
    return parseFloat(price).toFixed(0);
  };

  __itemAvailability = (item, variant = 0) => {
    if (!item.has_variant) return true;

    if (item.variant_type === "P") {
      if (variant !== undefined) {
        return variant.stock > 0 || variant.is_infinite || variant.is_negative;
      }

      let is_item_out_of_stock = item.variants.filter((variant) => {
        return (
          variant.is_default &&
          variant.stock <= 0 &&
          !variant.is_infinite &&
          !variant.is_negative
        );
      });
      return is_item_out_of_stock.length ? false : true;
    } else {
      if (variant !== undefined) {
        if (!variant.is_infinite && !variant.is_negative) {
          return item.variant_stock > 0;
        }
        return true;
      }

      let is_item_out_of_stock = item.variants.filter((variant) => {
        return (
          variant.is_default && (variant.is_infinite || variant.is_negative)
        );
      });
      if (is_item_out_of_stock.length > 0) {
        return true;
      }

      return item.variant_stock > 0;
    }
  };

  __getDiscountPercentage = (variant) => {
    if (parseFloat(variant.sale_price) >= parseFloat(variant.regular_price)) {
      return false;
    }
    let discount =
      ((parseFloat(variant.regular_price) - parseFloat(variant.sale_price)) /
        parseFloat(variant.regular_price)) *
      100;
    return discount !== 0 ? discount.toFixed(0) : false;
  };

  render() {
    const {
      product,
      removeProduct,
      cartProducts,
      addByDefault,
      showDefaultVariant,
      hideAddController,
      from,
    } = this.props;
    const isItemExistInCart = (item_id, variant_id) => {
      const { cartProducts } = this.props;
      let cc = cartProducts.find((cartItem) => {
        if (cartItem.selectedvariant !== undefined) {
          return (
            cartItem.id === item_id &&
            cartItem.selectedvariant.id === variant_id
          );
        }
      });
      if (cc !== undefined) {
        return true;
      }
      return false;
    };

    const defaultVariant = product.variants.find(
      (variant) => variant.is_default === 1
    );
    const totalAvailableVariants = product.variants.filter(
      (variant) =>
        variant.stock > 0 || variant.is_infinite || variant.is_negative
    );
    const products = this.props.cartProducts.filter(
      (item) => item.id === product.id
    );
    let count = 0;
    products.forEach((item) => {
      count = count + item.quantity;
    });

    return (
      <React.Fragment>
        {addByDefault === true ? (
          <React.Fragment>
            {showDefaultVariant && (
              <div className="position-relative">
                <div className="item-variant-actions py-2">
                  {product.variants.length === 1 ||
                  totalAvailableVariants.length === 1 ? (
                    <React.Fragment>
                      <button type="button" className="btn btn-default">
                        {defaultVariant.name} &nbsp;
                        <Ink duration="500" />
                      </button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={this.handlePopupOpen}
                      >
                        {defaultVariant.name} <i className="si si-arrow-down" />
                        <Ink duration="500" />
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
            {hideAddController === true &&
            !this.__itemAvailability(product, defaultVariant) ? (
              <div className="blur-content-single">
                {localStorage.getItem("outOfStockText")}
              </div>
            ) : (
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="btnGroupIcons1"
              >
                {isItemExistInCart(product.id, defaultVariant.id) ? (
                  <React.Fragment key={defaultVariant.id}>
                    <button
                      type="button"
                      className="btn btn-add-remove"
                      style={{
                        color: localStorage.getItem("cartColor-bg"),
                        backgroundColor: localStorage.getItem("cartColorBg"),
                      }}
                      onClick={() => {
                        product.quantity = 1;
                        product.selectedvariant = defaultVariant;
                        removeProduct(product);
                        this.forceStateUpdate();
                      }}
                    >
                      <span className="btn-dec">-</span>
                      <Ink duration="500" />
                    </button>

                    <input
                      type="text"
                      disabled
                      value={
                        cartProducts.find(
                          (cp) =>
                            cp.id === product.id &&
                            cp.selectedvariant.id === defaultVariant.id
                        ).quantity
                      }
                      data-variant-id={defaultVariant.id}
                      data-variant-name={defaultVariant.name}
                      data-variant-price={defaultVariant.price}
                      className="textbox-qty"
                    />

                    <button
                      type="button"
                      className="btn btn-add-remove"
                      style={{
                        color: localStorage.getItem("cartColor-bg"),
                        backgroundColor: localStorage.getItem("cartColorBg"),
                      }}
                      onClick={() => {
                        this.addProductVariant(product, defaultVariant);
                        this.forceStateUpdate();
                      }}
                    >
                      <span className="btn-inc">+</span>
                      <Ink duration="500" />
                    </button>
                  </React.Fragment>
                ) : (
                  //this is the culprit
                  <button
                    type="button"
                    className="btn btn-add-remove btn-custom-add"
                    style={{
                      minWidth: "66px",

                      color: localStorage.getItem("cartColor-bg"),
                      backgroundColor: localStorage.getItem("cartColorBg"),
                    }}
                    onClick={() => {
                      this.addProductVariant(product, defaultVariant);
                      this.forceStateUpdate();
                    }}
                  >
                    Add
                    <Ink duration="500" />
                  </button>
                )}
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <div className="item-variant-actions pb-0 mt-2">
              <button
                type="button"
                className="btn btn-default"
                onClick={this.handlePopupOpen}
              >
                {
                  product.variants.find(function(o) {
                    return o.is_default === 1;
                  }).name
                }{" "}
                <i className="si si-arrow-down" />
                <Ink duration="500" />
              </button>
            </div> */}
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="btnGroupIcons1"
            >
              <button
                type="button"
                className=" btn btn-add-remove btn-custom-add align-content justify-content "
                style={{
                  minWidth: "66px",
                  display: "flex",
                  color: cartProducts.find((cp) => cp.id === product.id)
                    ? "#ffffff"
                    : localStorage.getItem("cartColor-bg"),
                  backgroundColor: cartProducts.find(
                    (cp) => cp.id === product.id
                  )
                    ? "#f7fff9"
                    : localStorage.getItem("cartColor-bg"),
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32.97px !important",
                }}
                onClick={this.handlePopupOpen}
              >
                {" "}
                {cartProducts.find((cp) => cp.id === product.id) ? (
                  <>
                    <p className="btn-dec mb-0" style={{ width: "22px" }}>
                      -
                    </p>
                    <p
                      className="mx-2 green-color mb-0"
                      style={{ width: "22px" }}
                    >
                      {count}
                    </p>
                    <p className="btn-inc mb-0" style={{ width: "22px" }}>
                      +
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    {from !== "dynamic" && (
                      <span
                        className="position-absolute"
                        style={{
                          bottom: "-10px",
                          left: from === "dynamic" ? "10px" : "20px",
                          fontSize: "9px",
                          background: from === "dynamic" ? "" : "white",
                          padding: "2px",
                          color: from === "dynamic" ? "white" : "#318616",
                        }}
                      >
                        Options
                      </span>
                    )}
                    Add
                    <Ink duration="500" />
                  </>
                )}
              </button>
            </div>
          </React.Fragment>
        )}

        <Modal
          open={this.state.open}
          onClose={this.handlePopupClose}
          closeIconSize={32}
          classNames={{
            overlay: "variation-modal",
            // modal: "variation-modal",
          }}
        >
          <div
            style={{
              textAlign: "left",
            }}
          >
            <h4
              style={{
                marginBottom: "15px",
                marginLeft: "5px",
                fontSize: "15px",
                fontWeight: 600,
                color: "#1F1F1F",
              }}
            >
              {product.name}
            </h4>
            {product.variants.map((variant, index) => (
              <React.Fragment key={variant.id}>
                <div
                  className="single-variant-v "
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  {this.__getDiscountPercentage(variant) ? (
                    <div
                      className="item-discount-tag"
                      style={{
                        top: "-5px",
                        left: "10px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "53px" }}
                        alt="item-discount"
                        src="../../assets/img/clip-path.svg"
                      />
                      <span
                        className="discount-price"
                        style={{
                          width: "53px",
                          top: "5px",
                          position: "absolute",
                          left: "10px",
                          fontSize: "9px",
                          color: "white",
                        }}
                      >
                        {this.__getDiscountPercentage(variant)
                          ? this.__getDiscountPercentage(variant)
                          : 0}
                        % OFF
                      </span>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  <div>
                    <img
                      src={product.image}
                      alt=""
                      style={{ height: "52px", width: "52px" }}
                    />
                    <label
                      className="text addon-label mb-0 ml-20"
                      htmlFor={variant.name}
                    >
                      <span className=" f-12">{variant.name}</span> <br />
                    </label>
                  </div>
                  <div className="flex align-center">
                    <span className="f-12 mr-30">
                      <span className=" fw600 f-12">
                        {localStorage.getItem("currencySymbolAlign") ===
                          "left" && localStorage.getItem("currencyFormat")}
                        {this.__formatPrice(variant.sale_price)}
                      </span>
                      {variant.regular_price && (
                        <span className="fw400 f-12 ml-5">
                          {localStorage.getItem("currencySymbolAlign") ===
                            "left" && localStorage.getItem("currencyFormat")}
                          <s className="grey-color">
                            {this.__formatPrice(variant.regular_price)}
                          </s>
                        </span>
                      )}
                    </span>
                    {!this.__itemAvailability(product, variant) ? (
                      <div className="blur-content-single-variant">
                        {this.__formatPrice(
                          localStorage.getItem("outOfStockText")
                        )}
                      </div>
                    ) : (
                      <div className="item-actions pull-right">
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="btnGroupIcons1"
                          style={{ top: 0 }}
                        >
                          {isItemExistInCart(product.id, variant.id) ? (
                            <React.Fragment key={variant.id}>
                              <button
                                type="button"
                                className="btn btn-add-remove"
                                style={{
                                  backgroundColor: "#318616",
                                  color: "white",
                                }}
                                onClick={() => {
                                  product.quantity = 1;
                                  product.selectedvariant = variant;
                                  removeProduct(product);
                                  this.forceStateUpdate();
                                }}
                              >
                                <span className="btn-dec-v">-</span>
                                <Ink duration="500" />
                              </button>

                              <input
                                type="text"
                                disabled
                                value={
                                  cartProducts.find(
                                    (cp) =>
                                      cp.id === product.id &&
                                      cp.selectedvariant.id === variant.id
                                  ).quantity
                                }
                                data-variant-id={variant.id}
                                data-variant-name={variant.name}
                                data-variant-price={variant.price}
                                className="textbox-qty"
                                style={{
                                  backgroundColor: "#318616",
                                  color: "white",
                                }}
                              />

                              <button
                                type="button"
                                className="btn btn-add-remove"
                                style={{
                                  backgroundColor: "#318616",
                                  color: "white",
                                }}
                                onClick={() => {
                                  this.addProductVariant(product, variant);
                                  this.forceStateUpdate();
                                }}
                              >
                                <span className="btn-inc-v">+</span>
                                <Ink duration="500" />
                              </button>
                            </React.Fragment>
                          ) : (
                            <button
                              type="button"
                              style={{
                                minWidth: "66px",
                                width: "100px",
                                backgroundColor: "#f7fff9",
                                border: "0px",
                                minHeight: "32.95px",
                                borderRadius: "4px",
                                outline: "none",
                                color: "rgb(49, 134, 22)",
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                this.addProductVariant(product, variant);
                                this.forceStateUpdate();
                              }}
                            >
                              Add
                              <Ink duration="500" />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
            <button
              className="btn btn-lg btn-customization-done"
              onClick={() => {
                this.handlePopupClose();
              }}
              style={{
                backgroundColor: localStorage.getItem("cartColorBg"),
                color: localStorage.getItem("cartColorText"),
                height: "53px",
              }}
            >
              {localStorage.getItem("productVariationDoneBtnText")}
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

// export default Variations;

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
});

export default connect(
  mapStateToProps,
  {
    addProduct,
    removeProduct,
  }
)(Variations);
