import { GET_RESTAURANTS_SLIDES_URL } from "../../../../configs/index";
import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";
import LazyLoad from "react-lazyload";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { withRouter } from "react-router-dom";
import axios from "axios";
import PromoSlider from "../PromoSlider";
import BackWithSearch from "../../Elements/BackWithSearch";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import { getRestaurantsItemCategories } from "../../../../services/restaurant/actions";

class CategoryList extends Component {
  state = {
    total: null,
    restaurants_item_categories: [],
    loading: false,
    loading_more: true,
    no_restaurants_item_categories: false,
    data: [],
    review_data: [],
    isHomeDelivery: true,
  };

  componentDidMount() {
    this.getAllRestaurantSliders();
    if (localStorage.getItem("enRAR") === "true" && "enRAR" === "disRAR") {
      this.getRatableOrdersDetails();
    }
    this.__getRestaurantsItemCategories();
  }

  __getRestaurantsItemCategories = () => {
    if (localStorage.getItem("userSetAddress")) {
      this.setState({
        loading: true,
      });
      const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getRestaurantsItemCategories(userSetAddress.lat, userSetAddress.lng)
        .then((restaurants_item_categories) => {
          if (
            restaurants_item_categories &&
            restaurants_item_categories.payload.length
          ) {
            this.setState({
              total: restaurants_item_categories.payload.length,
              no_restaurants_item_categories: false,
              loading: false,
              loading_more: false,
            });
          } else {
            this.setState({
              total: null,
              no_restaurants_item_categories: true,
            });
            this.props.noAreaAlert(true);
          }
        });
    }
  };

  getAllRestaurantSliders = () => {
    axios.post(GET_RESTAURANTS_SLIDES_URL).then((response) => {
      if (response.data) {
        this.setState({
          data: response.data,
        });
      }
    });
  };

  changeRouteToRestaurantsCategories = (categories) => {
    if (categories.categories_ids) {
      const saveCategorySelectOptions = new Promise((resolve) => {
        localStorage.setItem(
          "categorySelectOptions",
          JSON.stringify(categories.categories_ids)
        );
        resolve("categorySelectOptions Saved");
      });
      saveCategorySelectOptions.then(() => {
        this.props.history.push("categories/stores");
      });
    }
  };

  render() {
    const { history } = this.props;
    const restaurant = this.props.match.params.restaurant;
    return (
      <React.Fragment>
        {restaurant !== undefined ? (
          <div className="bg-white">
            <React.Fragment>
              <BackWithSearch
                ref={(node) => {
                  this.child = node;
                }}
                history={history}
                boxshadow={false}
                has_restaurant_info={true}
                restaurant={restaurant}
                disable_search={true}
                homeButton={true}
                shareButton={true}
              />
            </React.Fragment>
          </div>
        ) : (
          <React.Fragment />
        )}
        <div
          className={
            "col-sm-12" +
            (restaurant !== undefined ? " pt-50 view-all-category-section" : "")
          }
        >
          <div className="home-page bg-white">
            {restaurant === undefined ? (
              <React.Fragment>
                {this.props.restaurants_item_categories.map(
                  (category, index) => (
                    <React.Fragment key={index}>
                      {localStorage.getItem("showPromoSlider") === "true" && (
                        <React.Fragment>
                          {this.props.slides &&
                            this.props.slides.length > 0 && (
                              <React.Fragment>
                                {0 ===
                                  this.props.slides[0]["promo_slider"][
                                    "position_id"
                                  ] && (
                                  <PromoSlider
                                    slides={this.props.slides}
                                    size={
                                      this.props.slides[0]["promo_slider"][
                                        "size"
                                      ]
                                    }
                                    secondarySlider={true}
                                  />
                                )}
                              </React.Fragment>
                            )}
                        </React.Fragment>
                      )}
                      {localStorage.getItem("enRestaurantCategorySlider") ===
                        "true" && (
                        <React.Fragment>
                          {index ===
                            parseInt(
                              localStorage.getItem(
                                "restaurantCategorySliderPosition"
                              )
                            ) -
                              1 && (
                            <React.Fragment>
                              {this.state.data.length > 0 && (
                                <div className="mt-10">
                                  <Carousel
                                    showThumbs={false}
                                    showArrows={false}
                                    showDots={false}
                                  >
                                    {this.state.data.map((category, key) => (
                                      <React.Fragment key={key}>
                                        <div>
                                          <img
                                            alt={category.name}
                                            src={"../" + category.image}
                                          />
                                        </div>
                                      </React.Fragment>
                                    ))}
                                  </Carousel>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )
                )}
                {localStorage.getItem("restaurantCategorySliderPosition") ===
                  "0" && (
                  <React.Fragment>
                    {this.state.data.length > 0 && (
                      <div className="slider-wrapper secondary-slider-wrapper my-0 pb-20">
                        {this.state.data.map((category) => (
                          <div
                            className="slider-wrapper__img-wrapper"
                            key={category.id}
                          >
                            <div
                              style={{ position: "relative" }}
                              onClick={() => {
                                this.changeRouteToRestaurantsCategories(
                                  category
                                );
                              }}
                            >
                              <img
                                src={category.image}
                                alt={category.name}
                                className="slider-wrapper__img slider-cust-img h-50"
                                style={{
                                  height:
                                    (12 / 5) *
                                      parseInt(
                                        localStorage.getItem(
                                          "restaurantCategorySliderSize"
                                        )
                                      ) +
                                    "rem",
                                  width:
                                    (12 / 5) *
                                      parseInt(
                                        localStorage.getItem(
                                          "restaurantCategorySliderSize"
                                        )
                                      ) +
                                    "rem",
                                  borderRadius:
                                    parseFloat(
                                      localStorage.getItem(
                                        "restaurantCategorySliderStyle"
                                      )
                                    ) + "rem",
                                }}
                              />
                              {localStorage.getItem(
                                "showRestaurantCategorySliderLabel"
                              ) === "true" && (
                                <span className="category-slider-name">
                                  {category.name}
                                </span>
                              )}
                              <Ink duration="500" hasTouch={true} />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {this.state.loading ? (
              <ContentLoader
                height={378}
                width={400}
                speed={1.2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
              </ContentLoader>
            ) : (
              <React.Fragment>
                <div className="shop-by-category-title-section mt-20 my-10">
                  <span className="title">
                    <strong>
                      {localStorage.getItem("categorySectionTitle")}
                    </strong>
                  </span>
                  {/* <span className="subtitle">
                    {localStorage.getItem("categorySectionSubTitle")}
                  </span> */}

                  {restaurant !== undefined ? (
                    <React.Fragment />
                  ) : (
                    <DelayLink
                      to={"../categories/" + restaurant}
                      delay={200}
                      className="block text-center mb-3 view-all"
                    >
                      View All
                      <i className="si si-arrow-right nav-location-icon ml-1" />
                    </DelayLink>
                  )}
                </div>
                <div
                  className={
                    restaurant === undefined
                      ? "shop-by-category-content-section v2-grid "
                      : "shop-by-category-content-section v2-grid mb-50 "
                  }
                >
                  {this.props.restaurants_item_categories.length === 0 ? (
                    <ContentLoader
                      height={378}
                      width={400}
                      speed={1.2}
                      primaryColor="#f3f3f3"
                      secondaryColor="#ecebeb"
                    >
                      <rect
                        x="20"
                        y="20"
                        rx="4"
                        ry="4"
                        width="80"
                        height="78"
                      />
                      <rect
                        x="144"
                        y="30"
                        rx="0"
                        ry="0"
                        width="115"
                        height="18"
                      />
                      <rect
                        x="144"
                        y="60"
                        rx="0"
                        ry="0"
                        width="165"
                        height="16"
                      />

                      <rect
                        x="20"
                        y="145"
                        rx="4"
                        ry="4"
                        width="80"
                        height="78"
                      />
                      <rect
                        x="144"
                        y="155"
                        rx="0"
                        ry="0"
                        width="115"
                        height="18"
                      />
                      <rect
                        x="144"
                        y="185"
                        rx="0"
                        ry="0"
                        width="165"
                        height="16"
                      />

                      <rect
                        x="20"
                        y="270"
                        rx="4"
                        ry="4"
                        width="80"
                        height="78"
                      />
                      <rect
                        x="144"
                        y="280"
                        rx="0"
                        ry="0"
                        width="115"
                        height="18"
                      />
                      <rect
                        x="144"
                        y="310"
                        rx="0"
                        ry="0"
                        width="165"
                        height="16"
                      />
                    </ContentLoader>
                  ) : (
                    this.props.restaurants_item_categories.map(
                      (category, index) => {
                        if (restaurant === undefined && index > 5) {
                          return;
                        }
                        return (
                          <React.Fragment key={index}>
                            <LazyLoad>
                              <div
                                className={`${restaurant === undefined &&
                                  " "} single-category-section card-shape`}
                              >
                                <DelayLink
                                  to={
                                    "../categories/" +
                                    category.restaurant_slug +
                                    "/" +
                                    category.id
                                  }
                                  delay={200}
                                  className="block text-center mb-1"
                                >
                                  <div
                                    className={`${
                                      restaurant === undefined
                                        ? "flex-column align-center "
                                        : ""
                                    } category-content`}
                                  >
                                    <img
                                      src={category.image}
                                      alt={category.image}
                                      className={`slider-cust-img `}
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                    {/* <div
                                    className={`flex-column p-5 ${
                                      restaurant !== undefined
                                        ? "ml-10 f-14"
                                        : ""
                                    } category-content`}
                                    style={{
                                      alignSelf: "center",
                                      textAlign:
                                        restaurant !== undefined
                                          ? "left"
                                          : "center !important",
                                    }}
                                  >
                                    {category.name}
                                    {restaurant !== undefined && (
                                      <>
                                        <br />{" "}
                                        <span className="f-12">
                                          {category.name}
                                        </span>
                                      </>
                                    )}
                                  </div> */}
                                  </div>
                                  <Ink duration="500" hasTouch={false} />
                                </DelayLink>
                              </div>
                            </LazyLoad>
                            {this.state.review_data.ratable &&
                              localStorage.getItem("enRAR") === "true" &&
                              localStorage.getItem("rarModEnHomeBanner") ===
                                "true" &&
                              "enRAR" === "disRAR" && (
                                <React.Fragment>
                                  {!this.state.loading &&
                                    index ===
                                      parseInt(
                                        localStorage.getItem(
                                          "rarModHomeBannerPosition"
                                        )
                                      ) -
                                        1 && (
                                      <div
                                        className="col-xs-12 col-sm-12 rating-block px-15 py-10"
                                        style={{
                                          backgroundColor: localStorage.getItem(
                                            "rarModHomeBannerBgColor"
                                          ),
                                        }}
                                      >
                                        <DelayLink
                                          to={{
                                            pathname:
                                              "../rate-and-review/" +
                                              this.state.review_data.data.id,
                                          }}
                                          delay={250}
                                        >
                                          <div className="d-flex justify-content-between align-items-center">
                                            <strong
                                              style={{
                                                color: localStorage.getItem(
                                                  "rarModHomeBannerTextColor"
                                                ),
                                              }}
                                            >
                                              {localStorage.getItem(
                                                "rarModHomeBannerText"
                                              )}
                                              {localStorage.getItem(
                                                "rarModShowBannerRestaurantName"
                                              ) === "true" &&
                                                this.state.review_data.data
                                                  .restaurant.name}
                                            </strong>
                                            <span
                                              className="btn btn-default"
                                              style={{
                                                minWidth: "100px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {this.animateStarIcon()}
                                            </span>
                                          </div>
                                          <Ink
                                            duration="500"
                                            hasTouch={false}
                                          />
                                        </DelayLink>
                                      </div>
                                    )}
                                </React.Fragment>
                              )}
                          </React.Fragment>
                        );
                      }
                    )
                  )}
                </div>
              </React.Fragment>
            )}

            {this.state.loading_more ? (
              <div className="">
                <ContentLoader
                  height={120}
                  width={400}
                  speed={1.2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                  <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                  <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
                </ContentLoader>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
  restaurants_item_categories: state.restaurant.restaurants_item_categories,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getRestaurantsItemCategories,
    }
  )(CategoryList)
);
