// Use this for Local Development
// export const WEBSITE_URL = "http://127.0.0.1/foodomaa/272/taazapanta";
// export const WEBSITE_URL = "http://192.168.0.178/foodomaa/272/taazapanta";

export const WEBSITE_URL = "https://taazapanta.com";
// export const WEBSITE_URL = "https://tp.ourdev.in";
// export const WEBSITE_URL = "https://tp.ourdev.in";

// Comment out the above and Uncomment the below line before building the files for Production
// export const WEBSITE_URL = "https://" + window.location.hostname;
